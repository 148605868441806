<template>
  <div id="department-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      v-if="department"
      :location="location"
      :get-department="getDepartment"
      :department="department"
      :employer="employer"
    />
  </div>
</template>

<script>

import Department from '@/application/models/Department.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { show } from '@/modules/department/api/department.ts';

export default {
  name: 'DepartmentLayout',
  components: {
    KSubMenu,
  },
  props: {
    employer: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    department: null,
  }),
  computed: {
    menuItems() {
      return [
        {
          title: this.$t('global.dossier'),
          route: { name: 'employer.location.department.show' },
        },
      ];
    },
    parentMenu() {
      return [
        {
          title: this.$tc('employer.title', 1),
          route: { name: 'employer.index' },
        },
        {
          title: this.$tc('location.title', 1),
          route: {
            name: 'employer.location.index',
            params: { employerId: this.employer.id },
          },
        },
        {
          title: this.$tc('department.title', 1),
          route: {
            name: 'employer.location.department.index',
            params: {
              locationId: this.location.id,
              employerId: this.employer.id,
            },
          },
        },
      ];
    },
  },
  watch: {
    '$route.params.departmentId': {
      immediate: true,
      handler() {
        this.getDepartment();
      },
    },
  },
  methods: {
    async getDepartment() {
      const response = await show(this.$route.params.departmentId);
      this.department = new Department().mapResponse(response.data.data);
    },
  },
};
</script>
